@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";

.component {
    padding-bottom: 20px;

    @include tablet {
        padding-bottom: 0;
    }

    &__section {
        margin-top: 120px;
        margin-bottom: 120px;

        @include tablet {
            margin-top: 80px;
            margin-bottom: 80px;
        }

        @include mobile {
            margin-top: 60px;
            margin-bottom: 60px;
        }

        &:last-child {
            margin-bottom: 60px;

            @include tabletOnly {
                margin-bottom: 40px;
            }
        }

        &_main,
        &_slider {
            margin-top: 0;
            margin-bottom: 0;
        }

        &_highlights {
            margin-top: 60px;
            margin-right: calc(var(--container-p-x, 0px) * -1);
            margin-left: calc(var(--container-p-x, 0px) * -1);
            padding-right: var(--container-p-x, 0px);
            padding-left: var(--container-p-x, 0px);

            @include tablet {
                margin-top: 40px;
            }

            @include mobile {
                margin-top: 32px;
            }
        }
    }
}
