@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";
@import "@Assets/functions";

.component {
    $p: &;

    display: flex;
    height: 600px;
    margin-right: calc(var(--container-p-x) * -1);
    margin-left: calc(var(--container-p-x) * -1);
    overflow: hidden;

    @include tablet {
        height: 320px;
    }

    @include mobile {
        height: 286px;
    }
}
