@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    position: relative;
    color: $color-cod-gray;

    &__header {
        display: flex;
        column-gap: 32px;
        align-items: center;

        @include mobile {
            column-gap: 20px;
            align-items: flex-end;
        }
    }

    &__link {
        display: block;
        position: relative;
        flex-shrink: 0;
        color: $color-mine-shaft;

        @include desktop {
            &:hover {
                color: $color-boulder;
            }
        }

        @include mobile {
            top: -4px;
            margin-left: auto;
        }
    }

    &__linkIcon {
        margin-left: 4px;
        font-size: 12px;

        path {
            stroke-width: 1;
        }

        @include mobile {
            font-size: 10px;
        }
    }

    &__arrows {
        display: flex;
        column-gap: 8px;
        align-items: center;
        margin-left: auto;
        font-size: 24px;

        @include tablet {
            column-gap: 19px;
        }
    }

    &__arrow {
        position: relative;
        transition: color $transition;

        @include desktop {
            &:hover {
                color: $color-boulder;
            }
        }

        &::before {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            margin-top: -20px;
            margin-left: -20px;
            content: "";
        }
    }

    &__slider {
        margin: 24px calc(var(--container-p-x) * -1) 0;
        padding: 0 var(--container-p-x);

        &:global(.swiper-css-mode) {
            padding: 0;

            :global(.swiper-wrapper) {
                padding: 0 var(--container-p-x);
            }
        }

        @include tablet {
            margin-top: 20px;
        }

        @include mobile {
            margin-top: 16px;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    &__slide {
        display: flex;
        flex-shrink: 0;
        width: vpw(330);
        height: auto;
        margin-right: var(--grid-column-gap);

        &:last-child {
            margin-right: 0;
        }

        @include tablet {
            width: vpwt(226);
        }

        @include mobile {
            width: vpwm(244);
        }
    }
}
