@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    &__slider {
        margin: inherit;
        padding: inherit;

        &:global(.swiper-css-mode) :global(.swiper-wrapper) {
            width: auto;
            margin-right: inherit;
            margin-left: inherit;
            padding-right: inherit;
            padding-left: inherit;
        }
    }

    &__slide {
        display: flex;
        flex: 1;
        width: auto;
    }

    &__list {
        display: flex;
        column-gap: 32px;

        &:global(.isSpaceBetween) {
            flex-grow: 1;
            justify-content: space-between;
        }

        @include tablet {
            column-gap: 24px;
        }

        @include mobile {
            column-gap: 8px;
        }
    }

    &__item {
        flex-shrink: 0;
        width: 136px;
        cursor: pointer;

        @media (min-width: 1920px) {
            width: 200px;
        }

        @include tablet {
            width: 72px;
        }
    }

    &__imageWrapper {
        width: 136px;
        height: 136px;
        padding: 7px;
        border: 1px solid $color-alto;
        border-radius: 50%;

        @media (min-width: 1920px) {
            width: 200px;
            height: 200px;
            padding: 11px;
        }

        @include tablet {
            width: 72px;
            height: 72px;
            padding: 5px;
        }
    }

    &__image {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 50%;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform $transition;
        }

        @include desktop {
            #{$p}__item:hover & img {
                transform: scale(1.05);
            }
        }
    }

    &__name {
        display: flex;
        justify-content: center;
        margin: 8px -10px 0;
        color: $color-cod-gray;
        transition: color $transition;

        @include desktop {
            #{$p}__item:hover & {
                color: $color-boulder;
            }
        }

        @include tablet {
            margin-top: 4px;
        }

        @include mobile {
            margin: 4px 0 0;
        }
    }
}
