@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    &__slide {
        @include mobile {
            width: vpwm(256);
        }
    }
}
