@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";
@import "@Assets/functions";

.component {
    $p: &;

    display: flex;
    min-height: vh(100);
    margin-right: calc(var(--container-p-x) * -1);
    margin-left: calc(var(--container-p-x) * -1);
    overflow: hidden;
}
