@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";

.component {
    $p: &;

    @include gridContainer;

    grid-auto-rows: 356px;
    gap: 12px;

    @include tablet {
        grid-auto-rows: 201px;
    }

    @include mobile {
        grid-auto-rows: auto;
        gap: 8px;
    }

    &__card {
        display: flex;
        z-index: 1;
        position: relative;
        grid-column: span 6;
        flex-direction: column;
        padding: 32px;
        background-color: $color-mine-shaft;
        color: $color-white;

        @include tablet {
            grid-column: span 4;
            padding: 20px;
        }

        @include mobile {
            grid-column: 1 / -1;
            min-height: 182px;
            padding: 16px;
        }

        &:nth-child(1) {
            grid-row: span 2;
            justify-content: center;

            @include tablet {
                grid-column: span 5;
            }

            @include mobile {
                grid-row: auto;
                grid-column: 1 / -1;
                min-height: 371px;
            }
        }
    }

    &__media {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        user-select: none;

        &::before {
            display: block;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color-black, 0.3);
            content: "";
        }

        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__text {
        margin-top: 4px;

        @include mobile {
            font-size: 14px;
            line-height: math.div(18, 14);
        }
    }

    &__link {
        position: absolute;
        bottom: 30px;
        left: 32px;

        @include desktop {
            &:hover {
                color: $color-dusty-gray;
            }
        }

        @include tablet {
            bottom: 18px;
            left: 20px;
        }

        @include mobile {
            bottom: 14px;
            left: 16px;
        }

        &::before {
            display: block;
            position: absolute;
            top: -10px;
            right: -5px;
            bottom: -10px;
            left: -5px;
            content: "";
        }

        svg {
            margin-left: 4px;
            font-size: 12px;

            path {
                stroke-width: 1;
            }

            @include mobile {
                font-size: 10px;
            }
        }
    }
}
