@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";

.component {
    $p: &;

    display: block;
    z-index: 1;
    position: relative;
    width: 100%;
    color: $color-cod-gray;

    &__body {
        position: relative;
        background-color: $color-wild-sand;
    }

    &__gallery {
        display: block;
        z-index: 2;
        position: relative;
        width: 100%;
        height: 0;
        padding-top: percentage(math.div(500, 330));
        overflow: hidden;

        @include tablet {
            padding-top: percentage(math.div(338, 226));
        }

        @include mobile {
            padding-top: percentage(math.div(330, 244));
        }

        &::before {
            display: block;
            z-index: 1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100px;
            background: linear-gradient(
                to bottom,
                rgba($color-black, 0),
                rgba($color-black, 0.28)
            );
            content: "";
            pointer-events: none;

            @include desktop {
                opacity: 0;
                transition: opacity $transition;

                #{$p}__body:hover & {
                    opacity: 1;
                }
            }

            @include tablet {
                height: 30px;
                opacity: 0.5;
            }

            @include mobile {
                height: 20px;
            }
        }

        &_noShadow::before {
            @include tablet {
                display: none;
            }
        }

        :global {
            .swiper-pagination-bullets {
                display: flex;
                z-index: 1;
                position: absolute;
                top: auto;
                right: 8px;
                bottom: 8px;
                left: 8px;
                column-gap: 4px;
                align-items: center;
                justify-content: space-between;
                width: auto;

                @include desktop {
                    transform: translateY(20px);
                    opacity: 0;
                    transition:
                        opacity $transition,
                        transform $transition;

                    #{$p}__body:hover & {
                        transform: none;
                        opacity: 1;
                    }
                }

                @include tabletOnly {
                    right: 6px;
                    bottom: 6px;
                    left: 6px;
                    column-gap: 6px;
                }

                @include mobile {
                    bottom: 4px;
                    column-gap: 2px;
                }
            }

            .swiper-pagination-bullets .swiper-pagination-bullet {
                display: block;
                flex: 1;
                width: auto;
                height: 2px;
                margin: 0;
                border-radius: 0;
                background-color: $color-white;
                opacity: 0.4;
                transition: opacity $transition;
            }

            .swiper-pagination-bullets .swiper-pagination-bullet-active {
                opacity: 1;
            }
        }
    }

    &__galleryItems {
        display: flex;
        z-index: 0;
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
    }

    &__galleryItem {
        display: flex;
        position: relative;
        width: 100%;
        overflow: hidden;

        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
            transition: transform $transition;
            user-select: none;

            @include desktop {
                #{$p}:hover & {
                    transform: scale(math.div(350, 330));
                }
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__hoverAreas {
        @include desktop {
            display: flex;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__hoverArea {
        @include desktop {
            flex: 1;
        }
    }

    &__labels {
        display: flex;
        z-index: 3;
        position: absolute;
        top: 16px;
        left: 0;
        row-gap: 4px;
        flex-direction: column;
        pointer-events: none;

        @include tabletOnly {
            top: 14px;
            row-gap: 7px;
        }

        @include mobile {
            top: 12px;
        }
    }

    &__label {
        min-width: 56px;
        padding: 4px;
        background-color: $color-mine-shaft;
        color: $color-white;

        &_special {
            background-color: $color-cod-gray;
        }

        @include mobile {
            min-width: 44px;
            padding: 3px;
        }
    }

    &__favorites {
        z-index: 3;
        position: absolute;
        top: 16px;
        right: 15px;
        font-size: 24px;
        transition: color $transition;

        svg path {
            transition: fill $transition;
        }

        &:global(.isActive) {
            svg path {
                fill: currentColor;
            }
        }

        @include desktop {
            &:hover {
                color: $color-boulder;
            }
        }

        @include tablet {
            top: 14px;
            right: 14px;
        }

        @include mobile {
            top: 12px;
            right: 12px;
        }

        &::before {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            margin-top: -20px;
            margin-left: -20px;
            content: "";
        }
    }

    &__view {
        @include desktop {
            display: flex;
            z-index: 3;
            position: absolute;
            right: 8px;
            bottom: 18px;
            left: 8px;
            flex-direction: column;
            transform: translateY(-20px);
            opacity: 0;
            transition:
                opacity $transition,
                transform $transition;

            #{$p}__body:hover & {
                transform: none;
                opacity: 1;
            }
        }
    }

    &__info {
        margin-top: 12px;
    }

    &__name {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        overflow: hidden;

        @include desktop {
            line-height: math.div(22, 18);
        }

        @include mobile {
            -webkit-line-clamp: 1;
        }
    }

    &__price {
        display: flex;
        align-items: center;
        margin-top: 12px;

        @include tabletOnly {
            font-weight: 700;
        }

        @include mobile {
            font-size: 18px;
            line-height: 1;
        }

        span {
            display: block;
            margin-right: 8px;
            color: $color-dusty-gray;
            font-size: 14px;
            line-height: 1;
            text-decoration: line-through;

            @include tablet {
                font-size: 12px;
            }
        }
    }

    &__link {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__outOfStock {
        z-index: 2;
        position: absolute;
        top: 50%;
        right: 8px;
        left: 8px;
        transform: translateY(-50%);
    }
}

.outOfStock {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8%;
    min-height: 40px;
    backdrop-filter: blur(4.5px);
    background-color: rgba($color-white, 0.4);
    color: $color-cod-gray;
    // box-shadow: 0 4px 30px rgba($color-black, 0.15);
    text-align: center;
}
