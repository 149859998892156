@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";

.component {
    $p: &;

    display: flex;
    position: relative;
    width: 100%;
    overflow: hidden;

    &__slider {
        z-index: 1;
        position: relative;
        width: 100%;
    }

    &__slide {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        background-color: $color-cod-gray;
        color: $color-white;
    }

    &__media {
        display: flex;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &::before {
            display: block;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateZ(0);
            background: rgba($color-black, 0.3);
            content: "";
        }
    }

    &__mediaItem {
        position: relative;
        flex: 1;

        img,
        video {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include tablet {
            display: none;

            &:first-child {
                display: block;
            }
        }
    }

    &__content {
        display: flex;
        z-index: 2;
        position: relative;
        flex-direction: column;
        align-items: center;
        padding: 125px calc(var(--container-p-x) + 50px);
        transform: translateZ(0);

        @include tablet {
            padding-top: 100px;
            padding-bottom: 100px;
        }

        @include mobile {
            align-items: stretch;
            width: 100%;
            padding-right: var(--container-p-x);
            padding-left: var(--container-p-x);
        }
    }

    &__text {
        margin-top: 6px;

        @include tablet {
            margin-top: 0;
        }

        @include mobile {
            margin-top: 4px;
        }
    }

    &__button {
        min-width: 330px;
        margin-top: 32px;

        @include tablet {
            min-width: 250px;
        }

        @include mobile {
            min-width: auto;
            height: 48px;

            #{$p}__title + & {
                margin-top: 25px;
            }
        }
    }

    &__arrow {
        z-index: 2;
        position: absolute;
        top: 50%;
        margin-top: -20px;
        color: $color-white;
        font-size: 40px;
        transition: color $transition;

        @include desktop {
            &:hover {
                color: $color-dusty-gray;
            }
        }

        @include tablet {
            margin-top: -11px;
            font-size: 22px;
        }

        @include mobile {
            display: none;
        }

        &::before {
            display: block;
            position: absolute;
            top: -5px;
            right: -5px;
            bottom: -5px;
            left: -5px;
            content: "";
        }

        &_prev {
            left: var(--container-p-x);
        }

        &_next {
            right: var(--container-p-x);
        }
    }

    &__pagination {
        display: flex;
        z-index: 2;
        position: absolute;
        top: auto !important;
        bottom: 12px !important;
        left: 50% !important;
        column-gap: 4px;
        align-items: center;
        justify-content: center;
        width: 432px !important;
        margin-left: -216px;

        @include tabletOnly {
            bottom: 20px !important;
        }

        @include mobile {
            right: var(--container-p-x) !important;
            left: var(--container-p-x) !important;
            column-gap: 2px;
            width: auto !important;
            margin-left: 0;
        }
    }

    &__paginationLine {
        display: block;
        position: relative;
        flex: 1;
        height: 2px;
        background-color: rgba($color-white, 0.4);
        cursor: pointer;

        &::before,
        &::after {
            display: block;
            position: absolute;
            content: "";
        }

        &::before {
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform-origin: left center;
            background-color: $color-white;
        }

        &::after {
            top: -15px;
            right: 0;
            bottom: -15px;
            left: 0;
        }

        &:global(.isActive) {
            &::before {
                transform: scaleX(var(--progress, 0));
            }

            ~ #{$p}__paginationLine::before {
                display: none;
            }
        }
    }
}
