@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: $color-black;
    pointer-events: none;

    &::before {
        display: block;
        z-index: 4;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateZ(0);
        background: rgba($color-black, 0.2);
        content: "";
        pointer-events: none;
        transition: opacity $transition;
    }

    :global(.swiper-slide-active) & {
        pointer-events: auto;

        &::before {
            opacity: 0;
        }
    }

    @include mobile {
        transform: translateZ(1px);

        &::before {
            display: none;
        }
    }

    &__pagination,
    &__slider::after,
    &__footer {
        opacity: 0;
        transition: opacity $transition;

        :global(.swiper-slide-active) & {
            opacity: 1;
        }
    }

    &__header,
    &__footer,
    &__slider::before,
    &__slider::after {
        transform: translateZ(0);

        #{$p}:global(.isPaused) & {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__header {
        display: flex;
        z-index: 3;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        row-gap: 12px;
        flex-direction: column;
        padding: 12px;
        pointer-events: none;
        transition: opacity $transition;
        user-select: none;
    }

    &__pagination {
        display: flex;
        position: relative;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        column-gap: 2px;
        align-items: center;
        justify-content: center;
        pointer-events: auto;
    }

    &__paginationLine {
        display: block;
        position: relative;
        flex: 1;
        height: 2px;
        background-color: rgba($color-white, 0.4);
        cursor: pointer;

        &::before,
        &::after {
            display: block;
            position: absolute;
            content: "";
        }

        &::before {
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform-origin: left center;
            background-color: $color-white;
        }

        &::after {
            top: -15px;
            right: 0;
            bottom: -15px;
            left: 0;
        }

        &:global(.isActive) {
            &::before {
                transform: scaleX(var(--progress, 0));
            }

            ~ #{$p}__paginationLine::before {
                display: none;
            }
        }
    }

    &__info {
        display: flex;
        column-gap: 12px;
        align-items: center;
        transition: transform $transition;

        #{$p}__pagination ~ & {
            transform: translateY(-12px);
        }

        :global(.swiper-slide-active) & {
            transform: none;
        }

        @include mobile {
            padding-right: 40px;

            #{$p}__pagination ~ & {
                transform: none;
            }
        }
    }

    &__imagePreview {
        position: relative;
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        overflow: hidden;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include tablet {
            width: 40px;
            height: 40px;
        }
    }

    &__name {
        font-size: 18px;
        line-height: 1;

        @include tablet {
            font-size: 16px;
        }
    }

    &__slider {
        z-index: 1;
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;

        &::before,
        &::after {
            display: block;
            z-index: 2;
            position: absolute;
            right: 0;
            left: 0;
            transform: translateZ(0);
            backface-visibility: hidden;
            content: "";
            pointer-events: none;
            transition: opacity $transition;
        }

        &::before {
            top: 0;
            height: percentage(math.div(264, 756));
            background: linear-gradient(
                to bottom,
                rgba($color-black, 0.71),
                rgba($color-black, 0)
            );
        }

        &::after {
            bottom: 0;
            height: percentage(math.div(121, 756));
            background: linear-gradient(
                to bottom,
                rgba($color-black, 0),
                rgba($color-black, 0.46)
            );
        }

        @include mobile {
            :global {
                .swiper-wrapper {
                    transform-style: unset;
                }

                .swiper-slide {
                    visibility: unset;
                    z-index: unset;
                    transform-style: unset;
                }
            }
        }
    }

    &__slide {
        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__buttonChangeSlide {
        display: block;
        z-index: 2;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50%;

        &_prev {
            left: 0;
        }

        &_next {
            right: 0;
        }
    }

    &__footer {
        z-index: 3;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 12px;
    }

    &__buttonLink {
        width: 100%;

        @include mobile {
            height: 48px;
        }
    }
}
