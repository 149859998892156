@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";

.component {
    $p: &;

    @include gridContainer;

    &__content {
        grid-column: span 8;

        @include desktopSm {
            grid-column: span 10;
        }

        @include tablet {
            grid-column: 1 / -1;
        }
    }

    &__title {
        color: $color-cod-gray;
    }

    &__text {
        position: relative;
        height: 66px;
        margin-top: 20px;
        padding-bottom: 2px;
        overflow: hidden;

        &:first-child {
            margin-top: 0;
        }

        @include desktop {
            line-height: math.div(22, 18);
        }

        @include tablet {
            margin-top: 12px;
        }

        @include mobile {
            margin-top: 16px;
            padding-bottom: 1px;
        }

        &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 55px;
            background: linear-gradient(
                to bottom,
                rgba($color-white, 0),
                $color-white
            );
            content: "";
            pointer-events: none;
            transition: opacity $transition;

            @include mobile {
                height: 46px;
            }
        }

        &:global(.isVisible)::before {
            opacity: 0;
        }

        ul {
            li {
                position: relative;
                padding-left: 27px;

                @include mobile {
                    padding-left: 24px;
                }

                &::before {
                    display: block;
                    position: absolute;
                    top: 8px;
                    left: 12px;
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background-color: currentColor;
                    content: "";
                }
            }
        }
    }

    &__button {
        margin-top: 20px;
        transition: color $transition;

        @include desktop {
            &:hover {
                color: $color-boulder;
            }
        }

        @include tablet {
            margin-top: 12px;
        }

        @include mobile {
            margin-top: 32px;
        }
    }
}
