@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    z-index: 10000;
    top: -1px;
    bottom: -1px;
    height: auto;
    padding: 0;
    overflow: hidden;
    background-color: rgba($color-black, 0.6);
    color: $color-white;
    user-select: none;

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: none;
        height: 100%;
        background: none;
        opacity: 0;
        transition: opacity $transition;

        #{$p}:global(.isReady) & {
            opacity: 1;
        }
    }

    &__buttonClose {
        display: block;
        z-index: 2;
        position: absolute;
        top: 41px;
        right: 42px;
        font-size: 48px;
        transition: opacity $transition;

        #{$p}:global(.isPaused) & {
            opacity: 0;
            pointer-events: none;
        }

        @include desktop {
            &:hover {
                color: inherit;
                opacity: 0.75;
            }
        }

        @include tablet {
            top: var(--container-p-x);
            right: var(--container-p-x);
            font-size: 24px;
        }

        @include mobile {
            top: 34px;
            right: 12px;

            :global(.is-os-ios) & {
                transform: translateZ(1000px);
            }
        }
    }

    &__slider {
        z-index: 1;
        position: relative;
        width: 100%;

        @include mobile {
            height: 100%;

            :global(.is-os-ios) & {
                transform: rotateY(-0.0001deg);
                transform-style: preserve-3d;
            }
        }
    }

    &__wrapper {
        box-sizing: inherit;
        will-change: transform;
    }

    &__slide {
        display: flex;
        width: vh(math.div(428, 900) * 100);
        height: vh(math.div(756, 900) * 100);
        will-change: transform;

        @include mobile {
            width: 100%;
            height: 100%;
            transform-style: flat;

            &:global(.swiper-slide-active),
            &:global(.swiper-slide-next),
            &:global(.swiper-slide-prev) {
                z-index: 2;
            }
        }
    }

    &__arrow {
        @include clickableSpace;

        display: block;
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -20px;
        font-size: 40px;
        transition: opacity $transition;

        &_prev {
            margin-left: calc(vh(math.div(238, 900) * -100) - 40px);
        }

        &_next {
            margin-left: vh(math.div(238, 900) * 100);
        }

        &:global(.swiper-button-disabled) {
            opacity: 0.25;
            pointer-events: none;
        }

        @include desktop {
            &:hover {
                opacity: 0.75;
            }
        }

        @include tablet {
            font-size: 24px;

            &_prev {
                margin-left: calc(vh(math.div(238, 900) * -100) - 24px);
            }
        }
    }
}
